
const state = () => ({
  terminal: {},
  //system currency
  currency: {},
  // inside the charge or quickCharge process
  operationCurrency: {},
  availableCurrencies: [],
});

const getters = {
  getOperationCurrency: (state) => {
    if (state.operationCurrency.code) return state.operationCurrency;
    else return state.currency;
  },
};
const actions = {
  async getDefaultSettings(store, { api, lodash }) {
    let terminalSet = await store.dispatch('getTerminal', {api, lodash });
    if(!terminalSet){
      return false;
    }

    let state = store.state;
    let dictionaries = await api.dictionaries.getTransactionDictionaries();
    let currencies = dictionaries ? dictionaries.currencyEnum : [];
    // would be cached later, because 1348 bug on some systems
    if (currencies.length) {
      store.commit('setAvailableCurrencies', currencies);

      if (Object.keys(state.currency).length === 0) {
        await store.dispatch('changeCurrency', state.availableCurrencies[0]);
      }
    }

    return true;
  },
  async getTerminal(store, { api, lodash }){
    let state = store.state;
    
    let terminals = (await api.terminals.getTerminals());
    terminals = terminals ? terminals.data : [];

    if(terminals.length === 0){
      state.terminal = null;
      return false;
    }

    if (!state.terminal || !state.terminal.terminalID) {
      await store.dispatch('changeTerminal', {api, newTerminal: terminals[0].terminalID});
    } 
    else {
      let exists = lodash.some(terminals, t => t.terminalID === state.terminal.terminalID);
      if (!exists){
        await store.dispatch('changeTerminal', {api, newTerminal: terminals[0].terminalID});
      }else{
        //refresh terminal data
        await store.dispatch('changeTerminal', {api, newTerminal: state.terminal.terminalID});
      }
    }
    return state.terminal;
  },
  async refreshTerminal({state, commit }, { api }) {
    if (!state.terminal || !state.terminal.terminalID){
      return;
    }
    let terminal = await api.terminals
      .getTerminal(state.terminal.terminalID);

    commit('setTerminal', terminal);
  },
  async changeTerminal({ state, commit, dispatch }, { api, newTerminal }) {
    let terminal = await api.terminals
      .getTerminal(typeof (newTerminal) === "string" ? newTerminal : newTerminal.terminalID);

    commit('setTerminal', terminal);
  },
  async changeCurrency({ commit }, newCurrency) {
    commit('setCurrency', newCurrency);
  }
};

const mutations = {
  setTerminal(state, newTerminal) {
    state.terminal = newTerminal;
  },
  setCurrency(state, newCurrency) {
    state.currency = newCurrency;
  },
  setAvailableCurrencies(state, newCurrencies) {
    function getCurrencySymbol (currency, locale = 'he-EN') {
      return (0).toLocaleString(
          locale,
          {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
      ).replace(/\w*\s*/g, '');
    }

    state.availableCurrencies = newCurrencies.map(c => {
        return {
            code: c.code,
            description: getCurrencySymbol(c.code)
        }
    });
  },
  setOperationCurrency(state, newOperationCurrency) {
    state.operationCurrency = newOperationCurrency;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}