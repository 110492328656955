export default class ImportApi {
    constructor(base) {
        this.base = base;
        this.baseUrl = this.base.cfg.VUE_APP_TRANSACTIONS_API_BASE_ADDRESS;
        this.importUrl = this.baseUrl + '/api/import';
    }
   
    async get(params) {   
        if (!this.headers) {
            let data = await this.base.get(this.importUrl + '/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }
        let data = await this.base.get(this.importUrl + `/billing-deals/${params.terminalID}`, params);

        if (!data || data.status === "error")
            return null;

        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        data.data = data.data.map(d => this.base.format(d, this.$headers, dictionaries))

        data.headers = this.headers;

        return data;
    }

    async upload(terminalID, file){
        return this.base.postFile(this.importUrl + `/billing-deals/upload/${terminalID}`, file);
    }
}