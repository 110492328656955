import store from '../../store/index';

export default function (currency) {
    if(!currency)  return '';

    let loc = store.state.localization.currentLocale;
    if (loc == "en-IL") {
        //en-IL shows USD as US$ which is not acceptable
        //he-EN shows $ and maintains Israeli order with currency at the end, like 123$
        loc = "he-EN";
    }

    function getCurrencySymbol (locale, currency) {
        return (0).toLocaleString(
            locale,
            {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }
        ).replace(/\d\s/g, '')
    }

    return getCurrencySymbol(loc, currency);
};