export default class IncomeApi {
    constructor(base) {
        this.base = base;
        this.baseUrl = this.base.cfg.VUE_APP_REPORT_API_BASE_ADDRESS;
        this.incomeUrl = this.baseUrl + '/api/reports/income';
    }

    async get(params) {
        if (!this.headers) {
            let data = await this.base.get(this.baseUrl  + '/api/reports/$meta-income')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }

        let data = await this.base.get(this.incomeUrl, params);
        
        if(!data || data.status === "error")
            return null;

        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();
        
        data.data = data.data.map(d => this.base.format(d, this.$headers, dictionaries))

        data.headers = this.headers;

        return data;
    }

    async getExcel(params) {

        let data = await this.base.get(this.incomeUrl + '/$excel', params);
        
        return data;
    }
  
    // async getIncome(id){
    //   if (!this.headers) {
    //     let data = await this.base.get(this.baseUrl  + '/api/reports/$meta-income')
    //     this.headers = this.base._formatHeaders(data)
    //     this.$headers = data.columns
    //   }
    //   let transaction = await this.base.get(this.incomeUrl + `/${id}`);
    //   let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

    //   transaction = this.base.format(transaction, this.$headers, dictionaries)
    //   return transaction;
    // }
}