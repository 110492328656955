var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      staticClass: "ecbg",
      attrs: { id: "inspire", dir: _vm.$vuetify.rtl ? "rtl" : "ltr" },
    },
    [
      _c("app-version-overlay"),
      _c("idle-confirmation-overlay"),
      _c(
        "v-overlay",
        { attrs: { value: _vm.requestsCountStore > 0, "z-index": "10" } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      !_vm.available
        ? _c(
            "v-overlay",
            { attrs: { "z-index": "10" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "text-center",
                  attrs: { rounded: "", light: "" },
                },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "subtitle-1" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("SystemIsNotReadyToUse")) + " "
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "pb-1",
                          class: {
                            "pr-2": _vm.$vuetify.rtl,
                            "pl-2": !_vm.$vuetify.rtl,
                          },
                          attrs: { small: "", color: "error darken-2" },
                        },
                        [_vm._v("mdi-cancel")]
                      ),
                    ],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v(_vm._s(_vm.$t("NoAvailableTerminals"))),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "scroll-contain" },
        [_vm.renderReady ? _c("router-view") : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }