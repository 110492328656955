const directive = {
    bind: function (el, binding, vnode) {


        el.addEventListener('keydown', evts.restrictAlpha);
        el.addEventListener('input', evts.restrictAlpha);

    }
};

const evts = {
    restrictAlpha: function (e) {

        if (!/[a-zA-Z_0-9]/.test(e.key)) {
            e.preventDefault();
        }
    },

}


export default directive;