export default class WebHooksApi {
    constructor(base) {
        this.base = base;
        this.baseUrl = this.base.cfg.VUE_APP_TRANSACTIONS_API_BASE_ADDRESS;
        this.webHooksUrl = this.baseUrl + '/api/webhooks';
    }

    async getWebHooks(params){
        return this.base.get(this.webHooksUrl, params);
    }

}