export default class BillingDealsApi {
    constructor(base) {
        this.base = base;
        this.baseUrl = this.base.cfg.VUE_APP_TRANSACTIONS_API_BASE_ADDRESS;
        this.billingUrl = this.baseUrl + '/api/billing';
        this.invoiceOnlyBillingUrl = this.baseUrl + '/api/invoiceonlybilling';
        this.billingRequestUrl = this.baseUrl + '/api/billing/request';
        this.paymentIntentUrl = this.baseUrl + '/api/paymentIntent';
    }

    async get(params) {

        if (!this.headers) {
            let data = await this.base.get(this.billingUrl + '/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }

        let data = await this.base.get(this.billingUrl, params);

        if (!data || data.status === "error")
            return null;

        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        data.data = data.data.map(d => this.base.format(d, this.$headers, dictionaries))

        data.headers = this.headers;

        return data;
    }

    async getExcel(params) {
        let data = await this.base.get(this.billingUrl + '/$excel', params);
        return data;
    }

    async getBillingDeal(id, doNotFormatData = false) {
        if (!this.headers) {
            let data = await this.base.get(this.billingUrl + '/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }
        let billingDeal = await this.base.get(this.billingUrl + `/${id}`);
        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        if (doNotFormatData) {
            return billingDeal;
        }
        billingDeal = this.base.format(billingDeal, this.$headers, dictionaries)
        return billingDeal;
    }

    async createBillingDeal(data) {
        return await this.base.post(this.billingUrl, data);
    }

    async updateBillingDeal(id, data) {
        return await this.base.put(this.billingUrl + `/${id}`, data);
    }

    async createBillingDealInvoice(data) {
        return await this.base.post(this.invoiceOnlyBillingUrl, data);
    }

    async updateBillingDealInvoice(id, data) {
        return await this.base.put(this.invoiceOnlyBillingUrl + `/${id}`, data);
    }

    async pauseBillingDeal(id, data) {
        return await this.base.post(this.billingUrl + `/${id}/pause`, data);
    }

    async unpauseBillingDeal(id) {
        return await this.base.post(this.billingUrl + `/${id}/unpause`);
    }

    async triggerBillingDealsByTerminal(terminalID) {
        return await this.base.post(this.billingUrl + `/trigger-by-terminal/${terminalID}`);
    }

    async triggerBillingDeals(billingDealsID) {
        return await this.base.post(this.billingUrl + '/trigger-billing-deals', { billingDealsID });
    }

    async activateBillingDeals(billingDealsID) {
        return await this.base.post(this.billingUrl + '/activate-billing-deals', { billingDealsID });
    }

    async disableBillingDeals(billingDealsID) {
        return await this.base.post(this.billingUrl + '/disable-billing-deals', { billingDealsID });
    }

    async createBillingRequest(data) {
        return await this.base.post(this.billingUrl + '/request', data);
    }

    async getBillingLinks(params) {
        if (!this.headers) {
            let data = await this.base.get(this.billingRequestUrl + '/meta/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }

        let data = await this.base.get(this.billingRequestUrl + `/links/${params.terminalID}`, params);

        if (!data || data.status === "error")
            return null;

        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        data.data = data.data.map(d => this.base.format(d, this.$headers, dictionaries))

        data.headers = this.headers;

        return data;
    }

    async getBillingLinkAnyStatus(id){
        if (!this.headers) {
            let data = await this.base.get(this.billingRequestUrl + '/meta/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }
        let paymentIntent = await this.base.get(this.paymentIntentUrl + `/${id}` + '?showDeleted=true');
        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        paymentIntent = this.base.format(paymentIntent, this.$headers, dictionaries)
        return paymentIntent;
    }
}