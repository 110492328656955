var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("intercom"),
      _c("main-app-bar", {
        attrs: { drawer: _vm.drawer },
        on: {
          "update:drawer": function ($event) {
            _vm.drawer = $event
          },
        },
      }),
      _c("ec-nav", {
        attrs: { drawer: _vm.drawer },
        on: {
          "update:drawer": function ($event) {
            _vm.drawer = $event
          },
        },
      }),
      _c("main-content", {
        attrs: { drawer: _vm.drawer },
        on: {
          "update:drawer": function ($event) {
            _vm.drawer = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }