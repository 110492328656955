var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-autocomplete", {
    attrs: {
      items: _vm.terminals,
      "item-text": "label",
      "item-value": "terminalID",
      outlined: "",
      label: _vm.$t("Terminal"),
      rules: _vm.rules,
      disabled: _vm.disabled,
      clearable: _vm.clearable,
    },
    on: {
      change: function ($event) {
        return _vm.$emit("change", _vm.value)
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }