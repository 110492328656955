export default class ItemsApi {
    constructor(base) {
        this.base = base;
        this.baseUrl = this.base.cfg.VUE_APP_PROFILE_API_BASE_ADDRESS;
        this.itemsUrl = this.baseUrl + '/api/items';
    }

    async getItems(params) {
        if (!this.headers) {
            let data = await this.base.get(this.itemsUrl + '/$meta')
            this.headers = this.base._formatHeaders(data)
            this.$headers = data.columns
        }
        
        let data = await this.base.get(this.itemsUrl, params);
        let dictionaries = await this.base.dictionaries.$getTransactionDictionaries();

        data.data = data.data.map(d => this.base.format(d, this.$headers, dictionaries))

        return data;
    }

    async getItem(id, params){
        return await this.base.get(this.itemsUrl + `/${id}`, params);
    }

    // async getItem(id, terminalID) {
    //     const params = new URLSearchParams();
    //     params.append('terminalID', terminalID);
        
    //     const url = `${this.itemsUrl}/${id}?${params.toString()}`;
    //     return await this.base.get(url);
    //   }

    async updateItem(id, data){
        return await this.base.put(this.itemsUrl + `/${id}`, data);
    }

    async createItem(data){
        return await this.base.post(this.itemsUrl, data);
    }

    async deleteItem(id){
        return await this.base.delete(this.itemsUrl + `/${id}`);
    }
    async bulkDeleteItems(ids){
        return await this.base.post(this.itemsUrl + '/bulkdelete', ids);
    }

    async getExcel(params) {
        let data = await this.base.get(this.itemsUrl + '/$excel', params);
        return data;
    }

    async getImportItems(params) {
        let data = await this.base.get(this.itemsUrl + '/import', params);
        return data;
    }

    async getImportItemCategories(params) {
        let data = await this.base.get(this.itemsUrl + '/import/categories', params);
        return data;
    }
}